* {
    background-color: #23252C;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

container {
    max-width: 700px;
    margin: 10% auto;
    display: flex;
    flex-direction: column;
    background: #1A1B21;
    font-family: 'Raleway', sans-serif;
    border-radius: 25px;
}



header {
    max-width: 700px;
    align-self: center;
}

.header--logo {
    max-width: 100%;
    display: flex;
    height: 250px;
    background: #1A1B21;
}

titles {
    background: #1A1B21;
    color: #FFFFFF;
    text-align: center;
}

.my--name {
    background: #1A1B21;
    font-weight: 700;
    font-size: 45px;
    margin:auto;
    
}

.my--title {
    background: #1A1B21;
    font-size: 34px;
    color: #F3BF99;
    font-weight: 400;
}

.my--site {
    background: #1A1B21;
    font-size: 25px;
    font-weight: 100;
}

.buttons {
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    background-color: #1A1B21;
}

.links {
    text-decoration: none;
    text-align: right;
    border: 1px solid white;
    color: #374151;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 10px;
    cursor: pointer;
    width: 200px;
    height: 60px;
  }
  
.email {
    background-color: rgb(250, 250, 250);
    background-image: url("./Images/envelope--black.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 175px;
}

.email:hover,
.email:focus {
    background-color: #949494;
    background-image: url("./Images/envelope--white.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 175px;
}

.linkedin {
    background-color: #297fe5;
    color: #ffffff;
    background-image: url("./Images/linkedin.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 175px;
}

.linkedin:hover,
.linkedin:focus {
    background-color: #1c559b;
}


main {
    margin: 10%;
    background-color: #1A1B21;
    color: #FFFFFF;
}

.main--header {
    background-color: #1A1B21;
    font-size: 30px;
    font-weight: 700;
}

.main--text {
    background-color: #1A1B21;
    font-size: 25px;
    font-weight: 300;
}

footer {
    background-color: #161619;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    border-radius: 0 0 25px 25px;
}

.footer--background {
    background-color: #161619;
}

.footer--icons {
    width: 70px;
    margin: 10px;
    background-color: #161619;
    padding: 0%;
}